import React from 'react';
import styled from 'styled-components';
import { components } from 'herald-fe-shared';

import Page from '~/layout/LayoutMarketing';
import LargeCTA from '~/components/LargeCTA';
import Image from '~/components/Image';
import Opener, { USE_CASES } from '~/components/use-cases/Opener';
import QuotesSection from '~/components/use-cases/QuotesSection';
import UseCaseSection, {
  UseCaseScreenshot,
} from '~/components/use-cases/UseCaseSection';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
  }
  .container__text {
    flex: 0 0 50%;
  }

  .changelog .container__content {
    height: 30rem;
  }
  .two-screenshots {
    margin-left: 2rem;
    position: relative;
    height: 100%;
  }
  .two-screenshots__screenshot {
    position: absolute;
  }
  .two-screenshots__screenshot--big {
    right: -8rem;
    width: 100%;
    top: -2rem;
  }
  .two-screenshots__screenshot--small {
    left: 0;
    top: 3rem;
    width: 80%;
  }
  .two-screenshots--engage .two-screenshots__screenshot--big {
    left: -4rem;
    top: -4rem;
  }
  .two-screenshots--engage .two-screenshots__screenshot--small {
    width: 100%;
    left: initial;
    top: initial;
    right: 0rem;
    bottom: -4rem;
  }
  .section__title {
    display: flex;
    flex-flow: column;
  }
  .container--center .section__title {
    align-items: center;
  }
  @media screen and (max-width: 800px) {
    .two-screenshots--engage .two-screenshots__screenshot--big {
      top: 0rem;
    }
    .two-screenshots--engage .two-screenshots__screenshot--small {
      bottom: 0rem;
    }
  }
  @media screen and (max-width: 600px) {
    .two-screenshots__screenshot--small {
      margin-top: 2rem;
    }
    .changelog .container__content {
      height: 24rem;
    }
    .screenshots-section .section .container {
      flex-flow: column;
    }
    .screenshots-section .container__content {
      width: 100%;
      height: 24rem !important;
      margin-top: 2rem;
      margin-bottom: -2rem;
    }
    .two-screenshots {
      margin: 0;
    }
    .two-screenshots__screenshot--big {
      display: none;
    }
    .two-screenshots__screenshot--small {
      width: 100%;
      position: initial;
    }
    .section__title {
      align-items: center;
    }
  }
`;

const ConsolidateFeedback: React.FC = () => {
  return (
    <Page title={USE_CASES[3].title}>
      <Styled>
        <Opener sectionIndex={3} />
        <UseCaseSection
          orientation="left"
          gray={true}
          title={<>Reach out to customers to&nbsp;close the loop.</>}
          description={
            <p>
              Easily recall customers who've requested a product feature even if
              it's from eons ago. Herald stores enough context to allow your
              team to delight customers with a highly personalized email when
              you ship a feature they've wanted.
            </p>
          }
          contentStyle={{ maxWidth: 'none' }}
          content={
            <div className="two-screenshots two-screenshots--engage">
              <UseCaseScreenshot className="two-screenshots__screenshot two-screenshots__screenshot--big">
                <Image src="screenshots/engage-gmail.png" />
              </UseCaseScreenshot>
              <UseCaseScreenshot className="two-screenshots__screenshot two-screenshots__screenshot--small">
                <Image src="screenshots/engage-apple.png" />
              </UseCaseScreenshot>
            </div>
          }
        />
        <UseCaseSection
          orientation="right"
          title={
            <div className="section__title">
              <components.Pill style={{ marginBottom: 12, border: 'none' }}>
                Optional
              </components.Pill>
              Show customers you care.
            </div>
          }
          description={
            <p>
              Foster trust and transparency with customers by easily sharing a
              history of their feedback with them. Show them that their feedback
              is taken seriously and not lost in the ether. You can even
              integrate this functionality in your app in less than 5 minutes.
            </p>
          }
          textStyle={{ flex: '0 0 60%', maxWidth: 'none', marginRight: '2rem' }}
          content={
            <UseCaseScreenshot className="email__gmail">
              <Image src="screenshots/roadmap-personalized.png" />
            </UseCaseScreenshot>
          }
        />
        <div className="changelog">
          <UseCaseSection
            orientation="center"
            gray={true}
            title={
              <div className="section__title">
                <components.Pill style={{ marginBottom: 12, border: 'none' }}>
                  Optional
                </components.Pill>
                Publish a changelog.
              </div>
            }
            description={
              <p>
                Your team works hard to address items on your roadmap. Celebrate
                what you ship with a changelog and keep your most engaged
                customers in the loop about recent changes. As a bonus, the
                changelog provides an additional place to capture feedback on
                recent changes.
              </p>
            }
            contentStyle={{ maxWidth: '42rem' }}
            content={
              <UseCaseScreenshot>
                <a
                  href="https://portal.herald.fyi/portal/changelog"
                  target="_blank"
                >
                  <Image src="screenshots/changelog.png" />
                </a>
              </UseCaseScreenshot>
            }
          />
        </div>
        <div className="screenshots-section">
          <UseCaseSection
            orientation="right"
            title={
              <div className="section__title">
                <components.Pill style={{ marginBottom: 12, border: 'none' }}>
                  Optional
                </components.Pill>
                Share your roadmap to solicit&nbsp;feedback.
              </div>
            }
            description={
              <p>
                Herald allows your team to share a{' '}
                <strong>highly customizable product roadmap</strong> with
                customers and prospects and solicit their feedback early on.
              </p>
            }
            contentStyle={{ height: '16rem', maxWidth: 'none' }}
            content={
              <div className="two-screenshots">
                <UseCaseScreenshot className="two-screenshots__screenshot two-screenshots__screenshot--big">
                  <a href="https://portal.herald.fyi" target="_blank">
                    <Image src="screenshots/roadmap.png" />
                  </a>
                </UseCaseScreenshot>
                <UseCaseScreenshot className="two-screenshots__screenshot two-screenshots__screenshot--small">
                  <a href="https://portal.herald.fyi" target="_blank">
                    <Image src="screenshots/roadmap-new-idea.png" />
                  </a>
                </UseCaseScreenshot>
              </div>
            }
          />
        </div>

        <QuotesSection sectionId="involve" />
        <LargeCTA title="Ready to get customers involved in your development process?" />
      </Styled>
    </Page>
  );
};

export default ConsolidateFeedback;
